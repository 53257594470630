import { useRef } from "react";
import { Link } from "react-router-dom";
import Connect from "../../pages/Connect";
import CV from "../../pages/CV";
import Home from "../../pages/Home";
import Dropdown from "./Dropdown";
import classes from "./MainBar.module.css";

function MainBar() {
  const homeRef = useRef(null);
  const cvRef = useRef(null);
  const connectRef = useRef(null);

  const handleScroll = (arg) => {
    window.scrollTo({
      top: arg.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <header className={classes.header}>
        <nav className={classes.nav}>
          <div className={classes.title}> Welcome</div>
          <Dropdown />
          <ul className={classes.nav.ul}>
            <li className={classes.word}>
              <button
                className={classes.btn}
                id={classes.btn1}
                onClick={() => handleScroll(homeRef)}
              >
                Home
              </button>
            </li>
            <li className={classes.word}>
              <button
                className={classes.btn}
                id={classes.btn1}
                onClick={() => handleScroll(cvRef)}
              >
                CV
              </button>
            </li>
            <li className={classes.word}>
              <button
                className={classes.btn}
                id={classes.btn1}
                onClick={() => handleScroll(connectRef)}
              >
                Contact
              </button>
            </li>
          </ul>
        </nav>
      </header>

      <div className={classes.section} ref={homeRef}>
        <Home />
      </div>

      <div className={classes.section2} ref={cvRef}>
        <CV />
      </div>
      <div className={classes.section3} ref={connectRef}>
        <Connect />
      </div>
    </div>
  );
}

export default MainBar;
