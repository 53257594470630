import React, { useState, useRef } from "react";
import css from "../../src/pages/PagesCSS/Home.module.css";
import About from "./About";
// import Dropdown from "../component/layout/Dropdown";
// import index from ;

function Home(event) {
  const arrQN = [
    "What is my name?",
    "How old am I?",
    "What am I?",
    "Where do I live?",
  ];

  // const arrOfQ =
  const inText = (e) => {
    const arrQ = [
      "What is my name?",
      "How old am I?",
      "What am I?",
      "Where do I live?",
    ];
    const arrA = [
      "Hello, My name is Noam ofir.",
      "I am 27 years old",
      "I am Automation Developer Engineer.",
      "For the last 3 years I am living in Tel-Aviv.\n In the beginning of winter 22 I moved to madrid for few month.",
    ];

    for (let i = 0; i < arrQ.length; i++) {
      if (document.getElementById("question").value === arrQ[i]) {
        document.getElementById("question").value = "";
        document.getElementById("first").textContent = arrA[i];
        console.log("its work");
      }
    }
  };

  const elementCenterStyle = {
    textAlign: "center",
    marginLeft: "-19%",
  };
  return (
    <div className={css.cssf}>
      <label id={css.labeling}>Let get to know each other</label>
      <input type="text" placeholder="Ask me a question" id="question"></input>
      <div className={css.cssf2}>
        <button onClick={inText} id="answer">
          Just DO IT
        </button>
        <p id="first" style={elementCenterStyle}></p>
      </div>

      <div className={css.cssf1}>
        <h4 className={css.hoveron}>Hover me</h4>
        <div className={css.qfe}>
          <ul>
            {"Questions:"}
            {arrQN.map((q, index) => {
              return <li key={index}>{q}</li>;
            })}
          </ul>
        </div>
      </div>

      <div className={css.text}>
        <h4 className={css.hoveron}>About me</h4>
        <p className={css.qfe}>
          My name is Noam Ofir, 26 years old.
          <li className="fas fa-smile"></li>
          <br />I AM AUTOMATION DEVELOPER ENGINEER.
          <br />
        </p>
      </div>
    </div>
  );
}
export default Home;

<>
  <div>
    <h3 id={css.ti}>In this website you will expose to my Projects & CV</h3>
    <div className={css.logos}></div>
  </div>
  <h3 id={css.title}>
    In this website you will be exposed to my Projects & CV
  </h3>
</>;

<div id={css.links}>
  <div class="dropdown">
    <span class="label">
      <i class="fa-solid fa-link"></i>
      {""}
    </span>
    <ul class="items" id={css.onlyitems}>
      <li>
        <a target="_blank">
          <i class="fa-brands fa-github"></i>{" "}
        </a>
      </li>
      <li>
        <a>
          <i class="fa-brands fa-linkedin"></i>
        </a>
      </li>
    </ul>
  </div>
</div>;

<div className={css.divup}>
  {/* <div className={css.divbox} style={{ visibility: "visible" }}> */}
  <div className={css.divup1}>
    <h3>About Me</h3>
    <p id={css.text}>
      My Name is Noam Ofir , I am 26 years old.
      <br />
      Originally I am from Modiin-Maccabim-Reut,
      <br /> currently, I am from Tel Aviv.
      <br />
      I am working as a Automation Engineer for the last 3 years.
      <br />
      By that time I had managed to assimilate automation tools, <br />{" "}
      especially for regression tests.
      <br />
      I am always in the movement of learning. <br />
    </p>
  </div>

  <div className={css.divup2}>
    <h3>Out Of The Office</h3>
    <p>
      In my free time I am realy love to enrich my knowlagde.
      <br />
      I am taking a time in each day to learn somthing knew that
      <br />
      related to my postion or to learn new technology.
      <br />
      Beside that I am realy love to hung out with my friends, to freshing up,
      <br />
      to cook between meetings and to take a tripin weekends when I can.
      <br />
      Add to that, I am a sport fan - mostly football guy, but realy love to
      run.{" "}
    </p>
  </div>
  {/* </div> */}
</div>;

{
  /* <h2>HELLO</h2>
      <h2>HELLO</h2> */
}
{
  /* <About /> */
}
{
  /* <div>
        <About />
      </div> */
}
{
  /* <div className={css.about}> */
}
{
  /* <button
          className={css.button}
          id={css.me}
          // onClick={open}
          style={{ visibility: "hidden" }}
        >
          About Me
        </button> */
}
{
  /* </div> */
}

{
  /* <h3 id={css.title}>
        In this website you will be exposed to my Projects & CV
      </h3> */
}
