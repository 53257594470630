import { /*BrowserRouter as  Router,*/ Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import CV from "./pages/CV";
import Connect from "./pages/Connect";
import MainBar from "./component/layout/MainBar";
import About from "./pages/About";

function App() {
  return (
    <div>
      <MainBar />
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        {
          /* <Route path="/Connect" element={<Connect />} />
        <Route path="/CV" element={<CV />} />*/
          // <Route path="/About" element={<About />} />
        }
      </Routes>
    </div>
  );
}

export default App;
