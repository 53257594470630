import { useState, useRef } from "react";
import cardCSS from "./CardName.module.css";
import photo from "../files/my_photo.jpg";
import emailjs from "emailjs-com";

function CardName() {
  const [visible, setVisible] = useState("hidden");
  const changeStyle = () => setVisible("visible");

  const fillname = useRef();
  const fillemail = useRef();
  const fillcompany = useRef();
  const filldis = useRef();
  const form = useRef();

  function addData(event) {
    event.preventDefault();

    const enterName = fillname.current.value;
    const enterEmail = fillemail.current.value;
    const enterCompany = fillcompany.current.value;
    const enterDis = filldis.current.value;

    const allData = {
      name: enterName,
      email: enterEmail,
      company: enterCompany,
      discription: enterDis,
    };

    emailjs
      .sendForm("gmail", "gmail_test", form.current, "4ivbkWDHeKjCSOuEd")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    event.target.reset();

    console.log(allData);
  }

  const clickTo = () => {
    window.addEventListener("click", () => {
      if (visible === "hidden") changeStyle();
      else setVisible("hidden");
    });
  };

  return (
    <div>
      <div className={cardCSS.cardinfo} id="card">
        <img alt="me" id={cardCSS.photo} src={photo} width="350" height="350" />
        <h1 className={cardCSS.name}>Noam Ofir</h1>
        <p className={cardCSS.titlename1}>Automation Engineer</p>
        <p>
          <button id={cardCSS.contact} className="to contact" onClick={clickTo}>
            Connect me
          </button>
        </p>
      </div>

      <div
        id={cardCSS.myinfo}
        className="contact hidden"
        style={{ visibility: visible }}
      >
        {/* <button id={cardCSS.closecard} className='close'>&times;</button> */}
        <div id={cardCSS.insideinfo}>
          <a id={cardCSS.address}>
            <i className="fa fa-phone"></i>
          </a>
          <a id={cardCSS.phone}> 054-7338440</a>
          <br />

          <div className={cardCSS.details}>
            <h3 id={cardCSS.h}>
              <i className="material-icons">email</i>
            </h3>
            <form ref={form} onSubmit={addData}>
              <div id={cardCSS.name} className="name_attribute">
                <label htmlFor="title" id={cardCSS.titlename}>
                  Name
                </label>
                <input
                  ref={fillname}
                  name="name"
                  type="text"
                  placeholder=""
                  className="form-control"
                  id={cardCSS.j}
                ></input>
              </div>
              <div id={cardCSS.email}>
                <label id={cardCSS.titlemail}>Email</label>
                <input
                  ref={fillemail}
                  type="email"
                  placeholder=""
                  className="form-control"
                  name="email"
                  id={cardCSS.p}
                ></input>
              </div>
              <div id={cardCSS.company}>
                <label id={cardCSS.titlecomp}>Company</label>

                <input
                  ref={fillcompany}
                  type="text"
                  placeholder=""
                  className="form-control"
                  name="company"
                  id={cardCSS.t}
                ></input>
              </div>
              <div>
                <label id={cardCSS.titledis}>Message</label>
                <textarea
                  ref={filldis}
                  rows="5"
                  type="text"
                  placeholder=""
                  className="form-control"
                  name="message"
                  id={cardCSS.n}
                ></textarea>
              </div>
              <div>
                <input id={cardCSS.btnn} type="submit" value="Send"></input>
              </div>
            </form>
          </div>
          <p className={cardCSS.sent}>
            To close thise window please press contact again
          </p>
        </div>
      </div>
    </div>
  );
}

export default CardName;
