import CardName from "../Extra/CardName";
import clasess from "../../src/pages/PagesCSS/Connect.module.css";
import Dropdown from "../component/layout/Dropdown";

function Connect() {
  return (
    <div>
      <CardName />
    </div>
  );
}
export default Connect;
