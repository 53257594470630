import React from "react";
// const  pdfjs = require("pdfjs-dist/webpack");
import clasess from "../pages/PagesCSS/CV.module.css";

var pdfjsLib = window["pdfjs-dist/build/pdf"];

function CV() {
  pdfjsLib.getDocument("../../pdf.pdf").then((par) => {
    console.log("number of pages: " + par._pdfInfo.numPages);

    par.getPage(1).then((pg) => {
      const can = document.getElementById(clasess.my_canvas);
      const text1 = can.getContext("2d");
      const view = pg.getViewport(1.3);

      can.width = view.width;
      can.height = view.height;

      pg.render({
        canvasContext: text1,
        viewport: view,
      });
    });
  });

  return (
    <div>
      <canvas id={clasess.my_canvas}></canvas>
    </div>
  );
}
export default CV;
