import React from "react";
import css from "../../component/layout/Dropdown.module.css";

function Dropdown() {
  const clickGit = () => {
    window.open("https://github.com/Noam17135/MyJavaProject");
  };

  const clickLink = () => {
    window.open(
      "https://www.linkedin.com/public-profile/settings?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_self_edit_contact-info%3BDgBYYBRxQFqgzzPMQCfIYg%3D%3D"
    );
  };

  return (
    <div className={css.dropdown}>
      <a className={css.titleof}>EXPLORE</a>
      <div className={css.menu}>
        <a className={css.menu1} onClick={clickGit}>
          GitHub
        </a>
        <a className={css.menu2} onClick={clickLink}>
          Linkedin
        </a>
      </div>
    </div>
  );
}

export default Dropdown;
